import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  paymentStyle: {
  },
  contentPayment: {
    padding: '3rem 0',
    margin: 0,
  },
  itemOptionsStyle: {
    display: 'flex',
    flexDirection: 'column',
  },
  optionsStyle: {
    display: 'flex',
    minHeight: '3rem',
    marginBottom: '2.5rem',
    alignItems: 'center',

  },
  contentOptions: {
    width: '4.8rem',
    height: '2.8rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${theme.colors.bgMedium}`,
    borderRadius: '0.4rem',
    margin: '0 0.5rem',
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: (props) => `url('${props.iconDataUri}')`,
    '& img': {
      margin: 0,
    },
  },
  textOptions: {
    textAlign: 'left',
    lineHeight: 1.43,
    fontSize: '1.4rem',
    margin: 0,
    marginLeft: 9,
  },
  boxInput: {
    display: 'flex',
    minWidth: '3rem',
    padding: 0,
    marginLeft: '0.5rem',
    alignItems: 'center',
  },
  paymentDescription: {
    backgroundColor: '#f4f4f4',
    padding: 20,
    textAlign: 'initial',
    marginBottom: 20,
    '& p, & span': {
      lineHeight: 'initial',
    },
    '& p': {
      marginBottom: 5,
    },
  },
  textCheckbox: {
    textAlign: 'left',
    lineHeight: 1.43,
    fontSize: '1.4rem',
    margin: 0,
  },
  isCloseStyle: {
    marginBottom: '4rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    contentPayment: {
      padding: '4rem 0',
    },
    isCloseStyle: {
      marginBottom: '7rem',
    },

  },
  sectionHeader: {
    textAlign: 'left',
    fontSize: '1.67rem',
    margin: 0,
    marginLeft: 9,
    marginBottom: '1.1rem',
  },
  button: {
    width: 'auto',
    marginRight: '2rem',
    textDecoration: 'underline',
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
    '&:last-child': {
      margin: 0,
    },
  },
}))

export default useStyles
