import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  shipping: {
    textAlign: 'left',
    paddingTop: '3.1rem',
    '& h4': {
      fontSize: '1.6rem',
      lineHeight: 1.14,
      fontWeight: 600,
      margin: 0,
      textTransform: 'uppercase',
      marginBottom: '3rem',
    },
    '& button': {
      textAlign: 'center',
    },
  },
  errorMessage: {
    color: theme.colors.alert,
    marginBottom: '2rem',
  },
  optionsStyle: {
    display: 'flex',
    minHeight: '3rem',
    marginBottom: '2.5rem',
    alignItems: 'center',

  },
  contentOptions: {
    flex: '1',
    '& h6': {
      lineHeight: 1.15,
      fontSize: '1.4rem',
      margin: 0,
    },
    '& p': {
      marginBottom: 5,
      color: theme.colors.bgDark,
      lineHeight: 1.43,
    },
  },
  boxInput: {
    display: 'flex',
    flex: '0 1 auto',
    minWidth: '3rem',
    padding: '0 2rem 0 0',
    alignItems: 'center',
  },
  button: {
    minHeight: '4.8rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    shipping: {
      '& button': {
        width: '37rem',
      },
    },

  },
}))

export default useStyles
