/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from 'react'
import _ from 'lodash'
import { cancelRequest, useCartShipments, useUser } from 'react-omnitech-api'
import { validateShipment } from '../../../../helpers'
import DeliveryView from './delivery-view'
import getCartValidationErrors from '../../../../helpers/get-cart-validation-errors'

const DeliveryController = (props) => {
  const {
    deliveryAddresses,
    isolateCart,
    onConfirmDelivery,
    onError,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onSelectCourierService,
    onSelectDeliveryAddress,
    onSelectDeliveryDate,
    onSelectDeliveryTimeSlot,
    onSetLoading,
    onUpdateDeliveryAddress,
    onApiRefreshIsolateCart,
    resetProgress,
  } = props

  // prepare hook
  const {
    fetchCartShipmentAvailableDeliveryDates,
    fetchCartShipmentAvailableDeliveryTimeSlots,
    fetchCartShipmentCourierServices,
  } = useCartShipments()
  const { user } = useUser()

  // internal state
  const [availableCourierServices, setAvailableCourierServices] = useState([])
  const [availableDeliveryDate, setAvailableDeliveryDate] = useState([])
  const [availableDeliveryTimeSlots, setAvailableDeliveryTimeSlots] = useState([])
  const [showAddressForm, setShowAddressForm] = useState(false)
  const [showAddressBook, setShowAddressBook] = useState(false)
  const [showDeliveryDateTimeOptions, setShowDeliveryDateTimeOptions] = useState(true)
  const [selectCourierServiceSuccess, setSelectCourierServiceSuccess] = useState(true)
  const [
    showNoAvailableCourierServiceMessage,
    setShowNoAvailableCourierServiceMessage,
  ] = useState(false)
  const [
    showNoAvailableDeliveryDateMessage,
    setShowNoAvailableDeliveryDateMessage,
  ] = useState(false)
  const [
    showNoAvailableDeliveryTimeSlotsMessage,
    setShowNoAvailableDeliveryTimeSlotsMessage,
  ] = useState(false)
  const [updateDeliveryAddressLoading, setUpdateDeliveryAddressLoading] = useState(false)

  // local variable
  const isGuest = _.isEmpty(user)
  const shipment = _.get(isolateCart, 'cartShipments[0]', {})
  const shipmentCourierService = useMemo(() => (
    _.get(isolateCart, 'cartShipments[0].courierService', {})
  ), [isolateCart])
  const shipmentDeliveryAddress = _.get(shipment, 'deliveryAddress', {})
  const shipmentDeliveryAddressText = _.join(_.get(shipmentDeliveryAddress, 'formattedAddressLines', []))
  /* eslint-disable-next-line no-unused-vars */
  const [deliveryShippiment, setDeliveryShippiment] = useState({})
  const shipmentDeliveryDate = _.get(isolateCart, 'cartShipments[0].deliveryDate', '')
  const { usableFields, requiredFields } = shipment
  const deliveryDateUsable = _.includes(usableFields, 'delivery_date')
  const deliveryDateRequired = _.includes(requiredFields, 'delivery_date')
  const deliveryTimeSlotUsable = _.includes(usableFields, 'delivery_time_slot_id')
  const cartValidationErrors = _.get(isolateCart, 'validationErrors')
  const shipmentValidationErrors = getCartValidationErrors(cartValidationErrors, { type: 'shipment' })
  const submitDisabled = !validateShipment(shipment) || !_.isEmpty(shipmentValidationErrors)
  const defaultDeliveryAddress = _.find(deliveryAddresses, { isPrimary: true })
  const deliveryType = _.get(shipment, 'deliveryType', {})
  const requireCourierService = _.get(deliveryType, 'requireCourierService', false)
  const requireDeliveryAddress = _.get(deliveryType, 'requireDeliveryAddress', false)
  const requirePickupStore = _.get(deliveryType, 'requirePickupStore', false)

  async function handleFetchCartShipmentAvailableDeliveryDates(shipmentId) {
    cancelRequest.cancel(['fetchCartShipmentAvailableDeliveryDates'])
    try {
      onSetLoading(true)
      const { dates } = await fetchCartShipmentAvailableDeliveryDates({ shipmentId })
      setAvailableDeliveryDate(dates)
      if (_.isEmpty(dates)) {
        setShowNoAvailableDeliveryDateMessage(true)
        setShowNoAvailableDeliveryTimeSlotsMessage(false)
      }
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  async function handleFetchCartShipmentAvailableDeliveryTimeSlots(shipmentId) {
    cancelRequest.cancel(['fetchCartShipmentAvailableDeliveryTimeSlots'])
    try {
      onSetLoading(true)
      const {
        deliveryTimeSlots,
      } = await fetchCartShipmentAvailableDeliveryTimeSlots({ shipmentId })
      setAvailableDeliveryTimeSlots(deliveryTimeSlots)
      if (_.isEmpty(deliveryTimeSlots)) {
        setShowNoAvailableDeliveryTimeSlotsMessage(true)
      }
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  async function handleFetchCartShipmentCourierServices(shipmentId) {
    cancelRequest.cancel(['fetchCartShipmentCourierServices'])
    try {
      onSetLoading(true)
      const { courierServices } = await fetchCartShipmentCourierServices({ shipmentId })
      setAvailableCourierServices(courierServices)

      if (courierServices.length === 1 && _.isEmpty(shipmentCourierService)) {
        // when only a single option, auto apply it to shipment
        handleSelectCourierService(_.first(courierServices))
      } else if (courierServices.length === 0) {
        handleNoAvailableCourierService()
      }
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  function handleNoAvailableCourierService() {
    setShowNoAvailableCourierServiceMessage(true)
  }

  async function handleSelectCourierService(courierService) {
    cancelRequest.cancel([
      'fetchCartShipmentAvailableDeliveryDates',
      'fetchCartShipmentAvailableDeliveryTimeSlots',
    ])
    try {
      onSetLoading(true)
      setAvailableDeliveryDate([])
      setAvailableDeliveryTimeSlots([])
      await onSelectCourierService(courierService)
      setSelectCourierServiceSuccess(true)
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  function handleCloseAddressBook() {
    setShowAddressBook(false)
    onApiRefreshIsolateCart()
  }

  async function handleOpenAddressBook() {
    try {
      await onFetchDeliveryAddresses()
      resetProgress()
      setShowAddressBook(true)
      setShowDeliveryDateTimeOptions(requireCourierService)
    } catch (error) {
      onError(error)
    }
  }

  function handleConfirmDelivery() {
    onConfirmDelivery()
    setShowDeliveryDateTimeOptions(false)
  }

  function handleOpenDeliveryDateTimeOptions() {
    setShowDeliveryDateTimeOptions(requireCourierService)
  }

  async function handleSelectAddress(address) {
    try {
      onSetLoading(true)
      setUpdateDeliveryAddressLoading(true)
      // TODO: set shipment delivery address id
      // when success, show info
      await onSelectDeliveryAddress(address)
      setShowAddressBook(false)
      setShowAddressForm(false)
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
      setUpdateDeliveryAddressLoading(false)
    }
  }

  async function handleSelectDeliveryDate(value) {
    try {
      onSetLoading(true)
      // when success, show info
      setAvailableDeliveryTimeSlots([])
      await onSelectDeliveryDate(value)
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  async function handleSelectDeliveryTimeSlot(value) {
    try {
      onSetLoading(true)
      // when success, show info
      await onSelectDeliveryTimeSlot(value)
    } catch (error) {
      onError(error)
    } finally {
      onSetLoading(false)
    }
  }

  // initial delivery section
  async function initDelivery() {
    if (isGuest) {
      // is guest, show add address form
      setShowAddressForm(true)
      return
    }

    if (
      _.isEmpty(shipmentDeliveryAddress)
      && _.includes(usableFields, 'delivery_address_id')
    ) {
      const addresses = await onFetchDeliveryAddresses()
      if (_.isEmpty(addresses)) {
        setShowAddressForm(true)
      } else {
        const defaultAddress = _.find(addresses, { isPrimary: true })
        await handleSelectAddress(defaultAddress)
      }
    }

    // the available courier service is fetch inside the useEffect

    if (!_.isEmpty(shipmentCourierService) && deliveryDateUsable) {
      handleFetchCartShipmentAvailableDeliveryDates(shipment.id)
    }

    if (
      (
        !_.isEmpty(shipmentDeliveryDate)
        || !deliveryDateRequired
      )
      && deliveryTimeSlotUsable
    ) {
      handleFetchCartShipmentAvailableDeliveryTimeSlots(shipment.id)
    }
  }

  // useEffect
  useEffect(() => {
    initDelivery()
  }, [])

  /**
   * fetch courier services when delivery address is changed
   */
  useEffect(() => {
    if (updateDeliveryAddressLoading) return
    if (_.isEmpty(shipmentDeliveryAddress) && requireDeliveryAddress) return
    setShowNoAvailableCourierServiceMessage(false)
    setShowNoAvailableDeliveryDateMessage(false)
    setShowNoAvailableDeliveryTimeSlotsMessage(false)
    handleFetchCartShipmentCourierServices(shipment.id)
    if (requireDeliveryAddress) {
      setDeliveryShippiment(shipmentDeliveryAddress)
    }

    // cancel request
    return function cleanUp() {
      cancelRequest.cancelAll([
        'fetchCartShipmentCourierServices',
      ])
    }
  }, [shipmentDeliveryAddress, shipmentDeliveryAddressText, updateDeliveryAddressLoading])

  /**
   * fetch delivery date options when courier services is changed
   */
  useEffect(() => {
    if (
      _.isEmpty(shipmentCourierService)
      || !selectCourierServiceSuccess
      || !deliveryDateUsable
    ) return

    setSelectCourierServiceSuccess(false)
    setShowNoAvailableDeliveryDateMessage(false)
    setShowNoAvailableDeliveryTimeSlotsMessage(false)
    handleFetchCartShipmentAvailableDeliveryDates(shipment.id)
  }, [
    deliveryDateUsable,
    shipmentCourierService,
    selectCourierServiceSuccess,
  ])

  /**
   * fetch delivery time slot when delivery date is changed
   */
  useEffect(() => {
    if (
      (_.isEmpty(shipmentDeliveryDate) && deliveryDateRequired)
      || !deliveryTimeSlotUsable
    ) return

    setShowNoAvailableDeliveryTimeSlotsMessage(false)
    handleFetchCartShipmentAvailableDeliveryTimeSlots(shipment.id)

    // cancel request
    return function cleanUp() {
      cancelRequest.cancel([
        'fetchCartShipmentAvailableDeliveryTimeSlots',
      ])
    }
  }, [
    deliveryDateRequired,
    deliveryTimeSlotUsable,
    shipmentDeliveryDate,
    shipmentCourierService,
    requiredFields,
  ])

  const viewProps = {
    ...props,
    availableCourierServices,
    availableDeliveryDate,
    availableDeliveryTimeSlots,
    defaultDeliveryAddress,
    deliveryAddresses,
    deliveryDateUsable,
    deliveryTimeSlotUsable,
    isGuest,
    requireCourierService,
    requirePickupStore,
    shipment,
    shipmentDeliveryAddress,
    showAddressBook,
    showAddressForm,
    showDeliveryDateTimeOptions,
    showNoAvailableCourierServiceMessage,
    showNoAvailableDeliveryDateMessage,
    showNoAvailableDeliveryTimeSlotsMessage,
    submitDisabled,
    onCloseAddressBook: handleCloseAddressBook,
    onConfirmDelivery: handleConfirmDelivery,
    onDeleteAddress,
    onFetchDeliveryAddresses,
    onOpenAddressBook: handleOpenAddressBook,
    onSelectAddress: handleSelectAddress,
    onSelectCourierService: handleSelectCourierService,
    onSelectDeliveryDate: handleSelectDeliveryDate,
    onSelectDeliveryTimeSlot: handleSelectDeliveryTimeSlot,
    onOpenDeliveryDateTimeOptions: handleOpenDeliveryDateTimeOptions,
    onUpdateDeliveryAddress,
  }

  return (
    <DeliveryView {...viewProps} />
  )
}

export default DeliveryController
