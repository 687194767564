import _ from 'lodash'

/**
 * getCartValidationErrors
 * @param validationErrors
 * @param options
 */
export default function getCartValidationErrors(validationErrors, options = {}) {
  if (_.isEmpty(options)) return _.keys(validationErrors)
  const {
    type,
    ...filters
  } = options

  // Filter errors by type
  if (!_.isEmpty(type)) {
    switch (type) {
      case 'cart':
        return _.keys(_.pick(
          validationErrors,
          [
            'userNotEnoughLoyaltyPoints',
            'userCannotUseLoyaltyPoints',
          ],
        ))
      case 'skus':
        return _.keys(_.pick(
          validationErrors,
          [
            'inactiveSku',
            'skuRequireCouponNotEnoughCoupon',
          ],
        ))
      case 'shipment':
        return _.keys(_.pick(
          validationErrors,
          [
            'cartPriceTotalInsufficientForCourierService',
            'failedToCalculateShippingFee',
          ],
        ))
      case 'courierService':
        return _.keys(_.pick(
          validationErrors,
          [
            'cartPriceTotalInsufficientForCourierService',
          ],
        ))
      case 'cartCouponToken':
        return _.keys(_.pick(
          validationErrors,
          [
            'cartCouponTokenHasNoApplicableSku',
            'notAppliedVoucherCartCouponToken',
            'notOwnedSelfUseOnlyCartCouponToken',
          ],
        ))
      case 'couponToken':
        return _.keys(_.pick(
          validationErrors,
          [
            'invalidCouponToken',
            'cartCouponTokenHasNoApplicableSku',
            'notAppliedVoucherCartCouponToken',
            'couponTokenNonusableOnCommerceChannelCouponToken',
            'notOwnedSelfUseOnlyCartCouponToken',
            'missingManualTriggerCouponToken',
            'reachApplyLimitPerOrderCouponToken',
            'reachApplyLimitPerCartDiscountCouponToken',
          ],
        ))
      default:
        return _.keys(validationErrors)
    }
  }

  // Filter by ids
  return _.keys(_.pickBy(validationErrors, (error) => {
    const {
      cartCouponTokenIds,
      cartManualCartDiscountIds,
      cartShipmentId,
      cartShipmentIds,
      couponIds,
      couponTokenIds,
      courierServiceId,
      skuIds,
    } = _.get(error, 'data', {})
    const filterSize = _.size(_.keys(filters))
    let filterMatchCount = 0
    _.each(filters, (v, k) => {
      switch (k) {
        case 'cartCouponTokenId':
          filterMatchCount += _.includes(cartCouponTokenIds, v) ? 1 : 0
          break;
        case 'cartManualCartDiscountId':
          filterMatchCount += _.includes(cartManualCartDiscountIds, v) ? 1 : 0
          break;
        case 'cartShipmentId':
          filterMatchCount += _.includes(cartShipmentIds, v)
            || _.isEqual(cartShipmentId, v) ? 1 : 0
          break;
        case 'couponId':
          filterMatchCount += _.includes(couponIds, v) ? 1 : 0
          break;
        case 'couponTokenId':
          filterMatchCount += _.includes(couponTokenIds, v) ? 1 : 0
          break;
        case 'skuId':
          filterMatchCount += _.includes(skuIds, v) ? 1 : 0
          break;
        case 'courierServiceId':
          filterMatchCount += _.isEqual(courierServiceId, v) ? 1 : 0
          break;
        default:
          break;
      }
    })
    return filterSize === filterMatchCount
  }))
}
