/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useMemo, useState, useEffect } from 'react'
import _ from 'lodash'
import moment from 'moment'
import { useTranslation } from 'react-i18next'
import {
  Dropdown,
} from '../../../../../../ui'
import useStyles from './select-time-delivery-style'
import {
  customStylesOnlyText,
} from '../../../../../../ui/dropdown/custom-styles'

const SelectTimeDeliveryView = ({
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  shipment,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  requireDeliveryAddress,
  requireCourierService,
  showNoAvailableDeliveryDateMessage,
  showNoAvailableDeliveryTimeSlotsMessage,
}) => {
  const { t } = useTranslation()
  const [isClearable, setIsClearable] = useState(false)

  const getTimeslotLabel = (timeslot) => {
    const timeFormat = t('screens.checkout.checkout.catering.timeFormat', { defaultValue: 'LT' })
    return moment(`${moment().format('YYYY-MM-DD')} ${timeslot}`).format(timeFormat)
  }
  const availableDeliveryDateOptions = useMemo(() => (
    availableDeliveryDate.map((date) => (
      {
        label: date,
        value: date,
      }
    ))
  ), [availableDeliveryDate])

  const availableDeliveryTimeSlotOptions = useMemo(() => {
    if (_.isEmpty(availableDeliveryTimeSlots)) return []

    return availableDeliveryTimeSlots.map((slot) => {
      const slots = _.split(slot.label, '~')
      const label = _.join(
        _.map(
          slots,
          getTimeslotLabel,
        ),
        '~',
      )
      return {
        label,
        value: slot.id,
      }
    })
  }, [availableDeliveryTimeSlots])
  const selectedDeliveryDate = useMemo(() => {
    if (_.isEmpty(shipment) || _.isEmpty(shipment.deliveryDate)) return

    return _.get(shipment, 'deliveryDate')
  }, [shipment])
  const selectedDeliveryTimeSlot = useMemo(() => {
    if (_.isEmpty(shipment)) return
    const selectedTimeslotId = _.get(shipment, 'deliveryTimeSlot.id')
    const selectedTimeslot = _.find(availableDeliveryTimeSlotOptions, { value: selectedTimeslotId })
    return _.get(selectedTimeslot, 'label')
  }, [shipment])

  const selectedDeliveryTimeSlotValue = useMemo(() => {
    if (_.isEmpty(shipment)) return

    return _.get(shipment, 'deliveryTimeSlot.id')
  }, [shipment])

  const handleSelectDeliveryDate = (selected) => {
    onSelectDeliveryDate(selected.value)
  }

  const handleSelectDeliveryTimeSlots = (selected) => {
    if (!_.isNull(selected)) {
      onSelectDeliveryTimeSlot(selected.value)
    } else {
      onSelectDeliveryTimeSlot(null)
    }
  }

  useEffect(() => {
    const clearInputTimeSlot = _.get(shipment, 'requiredFields')
    setIsClearable(!_.includes(clearInputTimeSlot, 'delivery_time_slot_id'))
  }, [shipment])

  const style = useStyles()
  return (
    <div className={style.selectTimeStyle}>
      {
        deliveryDateUsable && (
          showNoAvailableDeliveryDateMessage
            ? (
              <p className={style.errorMessage}>
                {t('screens.checkout.checkout.shipping.errors', { context: 'noAvailableDeliveryDateMessage' })}
              </p>
            )
            : (
              <Dropdown
                className={style.dropdownStyle}
                disabled={_.isEmpty(availableDeliveryDateOptions)}
                widthDropdown="calc(50% - 1rem)"
                placeholder={selectedDeliveryDate}
                isSearchable={false}
                customStyles={customStylesOnlyText}
                options={availableDeliveryDateOptions}
                label={
                  !requireDeliveryAddress && requireCourierService
                    ? t('screens.checkout.checkout.catering.date')
                    : t('screens.checkout.checkout.shipping.date')
                }
                onChange={handleSelectDeliveryDate}
              />
            )
        )
      }
      {
        deliveryTimeSlotUsable
        && !(showNoAvailableDeliveryDateMessage && deliveryDateUsable)
        && (
          showNoAvailableDeliveryTimeSlotsMessage
            ? (
              <p className={style.errorMessage}>
                {t('screens.checkout.checkout.shipping.errors', { context: 'noAvailableDeliveryTimeSlotsMessage' })}
              </p>
            )
            : (
              <Dropdown
                isClearable={isClearable}
                className={style.dropdownStyle}
                disabled={_.isEmpty(availableDeliveryTimeSlotOptions)}
                widthDropdown="calc(50% - 1rem)"
                placeholder={selectedDeliveryTimeSlot}
                valueSelect={selectedDeliveryTimeSlotValue}
                isSearchable={false}
                customStyles={customStylesOnlyText}
                options={availableDeliveryTimeSlotOptions}
                label={
                  !requireDeliveryAddress && requireCourierService
                    ? t('screens.checkout.checkout.catering.time')
                    : t('screens.checkout.checkout.shipping.time')
                }
                onChange={handleSelectDeliveryTimeSlots}
              />
            )
        )
      }
    </div>
  )
}

export default SelectTimeDeliveryView
