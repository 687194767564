import _ from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import moment from 'moment'
import PaymentStripeModalView from './payment-stripe-modal-view'

export default function PaymentStripeModalController({
  holdExpireAt,
  payParams,
  isOpen = false,
  onRequestClose,
  onPaymentSuccess,
}) {
  const confirmTimer = useRef(null)
  const expireTimer = useRef(null)

  const [errorMessage, setErrorMessage] = useState(null)
  const [loading, setLoading] = useState(false)
  const [confirmCount, setConfirmCount] = useState(0)
  const [expireCount, setExpireCount] = useState(null)

  const expireCountBuffer = 30 // seconds

  useEffect(() => {
    setErrorMessage(null)
  }, [payParams])

  useEffect(() => {
    if (isOpen) {
      setConfirmCount(3)
    } else {
      clearTimeout(confirmTimer.current)
      setConfirmCount(0)
    }
  }, [isOpen])

  useEffect(() => {
    if (confirmCount > 0) {
      clearTimeout(confirmTimer.current)
      confirmTimer.current = setTimeout(() => {
        setConfirmCount(confirmCount - 1)
      }, 1000)
    }
  }, [confirmCount])

  useEffect(() => {
    if (holdExpireAt && isOpen && confirmCount <= 0) {
      clearTimeout(expireTimer.current)
      expireTimer.current = setTimeout(() => {
        const seconds = _.max([moment(holdExpireAt).diff(moment(), 'seconds') - expireCountBuffer, 0])
        setExpireCount(seconds)
      }, 1000)
    } else {
      setExpireCount(null)
    }
  }, [holdExpireAt, isOpen, confirmCount, expireCount])

  async function handleConfirmStripeClick({ stripe, elements }) {
    if (!stripe || !elements) {
      return;
    }
    setErrorMessage(null)
    setLoading(true)
    try {
      const result = await stripe.confirmPayment({
        elements,
        redirect: 'if_required',
      })
      if (result.error) {
        setLoading(false)
        setErrorMessage(result.error.message)
      } else {
        onPaymentSuccess()
      }
    } catch (e) {
      setLoading(false)
      if (!_.isEmpty(e)) {
        setErrorMessage(e.toString())
      }
    }
  }

  const viewProps = {
    errorMessage,
    expireCount,
    isOpen,
    loading,
    payParams,
    confirmCount,
    onConfirmClick: handleConfirmStripeClick,
    onRequestClose,
  }

  if (_.isEmpty(payParams)) {
    return null
  }
  return (
    <PaymentStripeModalView {...viewProps} />
  )
}
