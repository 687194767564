/* eslint-disable react/no-danger */
/* eslint-disable react/no-array-index-key */
import React, { useMemo } from 'react'
import _ from 'lodash'
import useStyles from './info-style'
import { Button } from '../../../../ui'

const InfoView = ({
  address,
  t,
  title,
  shipment,
  isAddress,
  isHtmlAddress,
  onOpenAddressBook,
  canOpenAddressBook,
}) => {
  const { infoStyle, boxTilte, buttonInfoStyle } = useStyles()
  const {
    courierServiceName,
    deliveryDate,
    deliveryTimeSlot,
  } = useMemo(() => ({
    courierServiceName: _.get(shipment, 'courierService.name'),
    deliveryDate: _.get(shipment, 'deliveryDate'),
    deliveryTimeSlot: _.get(shipment, 'deliveryTimeSlot.label'),
  }), [shipment])
  if (isAddress && _.isEmpty(address)) {
    return null
  }

  return (
    <div className={infoStyle}>
      <div className={boxTilte}>
        <h3>{title}</h3>
        {
          canOpenAddressBook ? (
            <Button
              mini
              className={buttonInfoStyle}
              border
              text={t('screens.checkout.checkout.buttons.change')}
              onClick={onOpenAddressBook}
            />
          ) : ''
        }
      </div>
      {
        isAddress
          ? (
            <>
              {
                (address.firstName || address.lastName) && (
                  <p>{`${address.firstName} ${address.lastName}`}</p>
                )
              }
              {
                address.name && (
                  <p>{`${address.name}`}</p>
                )
              }
              {
                isHtmlAddress
                  ? (
                    <p
                      dangerouslySetInnerHTML={{
                        __html: _.first(address.formattedAddressLines),
                      }}
                    />
                  )
                  : address.formattedAddressLines.map((line, index) => (
                    <p key={`address-${address.id}-line-${index}`}>{line}</p>
                  ))
              }
              {
                !_.isEmpty(address.phone)
                  && (
                    <>
                      <br />
                      <p>{address.phone}</p>
                    </>
                  )
              }
            </>
          )
          : (
            <>
              {
                !_.isEmpty(courierServiceName) && (
                  <p>
                    {t('screens.checkout.checkout.shipping.courier')}
                    :
                    <span>{courierServiceName}</span>
                  </p>
                )
              }
              {
                !_.isEmpty(deliveryDate) && (
                  <p>
                    {t('screens.checkout.checkout.shipping.date')}
                    :
                    <span>{deliveryDate}</span>
                  </p>
                )
              }
              {
                !_.isEmpty(deliveryTimeSlot) && (
                  <p>
                    {t('screens.checkout.checkout.shipping.time')}
                    :
                    <span>{deliveryTimeSlot}</span>
                  </p>
                )
              }
            </>
          )
      }
    </div>

  )
}
export default InfoView
