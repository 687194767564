import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, ContentGroup } from '../../../../ui'
import useStyles from './confirmation-style'
import Modal from '../../../../ui/modal';
import {
  useThemeConfig,
} from '../../../../hook'
import PaymentQrCodeModal from '../payment-qr-code-modal'
import PaymentStripeModal from '../payment-stripe-modal'

const ConfirmationView = ({
  active,
  checkoutDisabled,
  holdExpireAt,
  isApplePayConfirmationDialogOpen,
  isStripeConfirmationDialogOpen,
  paymentQrCodeModalOpen,
  paymentQrCodeModalParams,
  stripePayParams,
  onApplePayDialogRequestClose,
  onConfirmApplePayClick,
  onConfirmPlaceOrder,
  onPaymentQrCodeModalClose,
  onPaymentQrCodeModalSuccess,
  onPaymentQrCodeModalError,
  onPaymentStripeModalSuccess,
  onStripeDialogRequestClose,
}) => {
  const { t } = useTranslation()
  const { getConfig, getContentGroup } = useThemeConfig()
  const {
    contentNotice,
    title,
    containerLogos,
    itemIconStyle,
    itemIconLargeStyle,
  } = useStyles()
  const styles = useStyles()

  const paymentLogosContentGroupProps = useMemo(() => (
    getContentGroup('config.ui.paymentSecureLogos')
  ), [getContentGroup])

  const isAvailable = getConfig('config.ui.paymentSecureLogos.available', false)

  if (!active) return null
  return (
    <div className={contentNotice}>
      <h4>{t('screens.checkout.checkout.coupon.notice')}</h4>
      <p>{t('screens.checkout.checkout.coupon.statement')}</p>
      <Button
        dark
        disabled={checkoutDisabled}
        text={t('screens.checkout.checkout.coupon.place')}
        onClick={onConfirmPlaceOrder}
      />
      <div>
        {
          isAvailable
            && (
            <ContentGroup
              titleStyle={title}
              containerStyle={containerLogos}
              itemIconStyle={itemIconStyle}
              itemIconLargeStyle={itemIconLargeStyle}
              {...paymentLogosContentGroupProps}
            />
            )
        }
      </div>
      <Modal
        dialog
        isOpen={isApplePayConfirmationDialogOpen}
        onRequestClose={onApplePayDialogRequestClose}
      >
        <div className={styles.dialogContainer}>
          <div className={styles.dialogBody}>
            <h1 className={styles.dialogTitle}>{t('screens.checkout.checkout.applePayConfirmationDialog.title')}</h1>
          </div>
          <div className={styles.dialogButtons}>
            <Button
              className={styles.button}
              dark
              text={t('screens.checkout.checkout.applePayConfirmationDialog.buttons.confirm')}
              onClick={onConfirmApplePayClick}
            />
            <Button
              className={styles.button}
              border
              text={t('screens.checkout.checkout.applePayConfirmationDialog.buttons.cancel')}
              onClick={onApplePayDialogRequestClose}
            />
          </div>
        </div>
      </Modal>
      <PaymentStripeModal
        holdExpireAt={holdExpireAt}
        isOpen={isStripeConfirmationDialogOpen}
        payParams={stripePayParams}
        onRequestClose={onStripeDialogRequestClose}
        onPaymentSuccess={onPaymentStripeModalSuccess}
      />
      <PaymentQrCodeModal
        isOpen={paymentQrCodeModalOpen}
        onRequestClose={onPaymentQrCodeModalClose}
        onPaymentSuccess={onPaymentQrCodeModalSuccess}
        onPaymentError={onPaymentQrCodeModalError}
        {...paymentQrCodeModalParams}
      />
    </div>
  )
}

export default ConfirmationView
