import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  selectTimeStyle: {
    marginBottom: '3rem',
  },
  dropdownStyle: {
    paddingTop: '2.5rem',
    width: '100%',
    '& label': {
      textTransform: 'uppercase',
      fontSize: '1.2rem',
      fontWeight: 600,
      letterSpacing: -0.45,
    },
  },
  errorMessage: {
    color: theme.colors.alert,
    marginBottom: '2rem',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    selectTimeStyle: {

    },
    dropdownStyle: {
      width: 'calc(60% - 1rem)',
      maxWidth: '37rem',
    },

  },
}))

export default useStyles
