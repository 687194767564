/* eslint-disable react/jsx-no-bind */
import React from 'react'
import _ from 'lodash'
import { useTranslation } from 'react-i18next'
import useStyles from './shipping-style'
import { InputRadio, CurrencyFormat, Button } from '../../../../../../ui';
import SelectTimeDelivery from '../select-time-delivery/select-time-delivery-view';

const ShippingView = ({
  availableCourierServices,
  availableDeliveryDate,
  availableDeliveryTimeSlots,
  deliveryDateUsable,
  deliveryTimeSlotUsable,
  isolateCart,
  showNoAvailableCourierServiceMessage,
  showNoAvailableDeliveryDateMessage,
  showNoAvailableDeliveryTimeSlotsMessage,
  submitDisabled,
  onConfirmDelivery,
  onSelectCourierService,
  onSelectDeliveryDate,
  onSelectDeliveryTimeSlot,
  requireDeliveryAddress,
  requireCourierService,
}) => {
  // prepare hook
  const { t } = useTranslation()

  // local variables
  const shipment = _.get(isolateCart, 'cartShipments[0]')
  const shippingFee = _.get(isolateCart, 'priceDetails.cartDeliveryFee')
  const shipmentCourierServiceId = _.get(isolateCart, 'cartShipments[0].courierService.id')
  const shipmentRequiredFields = _.get(shipment, 'requiredFields', [])
  const deliveryDateTimeFulfilled = !(
    (_.includes(shipmentRequiredFields, 'delivery_date') && _.isEmpty(_.get(shipment, 'deliveryDate')))
    || (_.includes(shipmentRequiredFields, 'delivery_time_slot_id') && _.isEmpty(_.get(shipment, 'deliveryTimeSlotId')))
  )

  // styles
  const style = useStyles()

  const selectTimeDeliveryProps = {
    shipment,
    availableDeliveryDate,
    availableDeliveryTimeSlots,
    deliveryDateUsable,
    deliveryTimeSlotUsable,
    onSelectDeliveryDate,
    onSelectDeliveryTimeSlot,
    requireDeliveryAddress,
    requireCourierService,
    showNoAvailableDeliveryDateMessage,
    showNoAvailableDeliveryTimeSlotsMessage,
  }

  return (
    <div className={style.shipping}>
      <h4>{t('screens.checkout.checkout.shipping.title')}</h4>
      {
        showNoAvailableCourierServiceMessage && (
          <p className={style.errorMessage}>
            {t('screens.checkout.checkout.shipping.errors', { context: 'noAvailableCourierServiceMessage' })}
          </p>
        )
      }
      <div>
        <form>
          {
            availableCourierServices.map((courierService) => {
              const isSelected = shipmentCourierServiceId === courierService.id
              const showShippingFee = deliveryDateTimeFulfilled && shippingFee && isSelected
              const renderLabel = () => (
                <div className={style.contentOptions}>
                  <h6>{courierService.name}</h6>
                  { courierService.summary && <p>{courierService.summary}</p> }
                  {
                    showShippingFee && (
                      <h6><CurrencyFormat value={shippingFee} /></h6>
                    )
                  }
                </div>
              )
              return (
                <React.Fragment key={`courier-service-${courierService.id}`}>
                  {
                    availableCourierServices.length === 1
                    && (
                      <div className={style.optionsStyle}>
                        {renderLabel()}
                      </div>
                    )
                  }
                  {
                    availableCourierServices.length > 1
                    && (
                    <label htmlFor={courierService.id} className={style.optionsStyle}>
                      <div className={style.boxInput}>
                        <InputRadio
                          id={courierService.id}
                          checked={isSelected}
                          onChange={onSelectCourierService.bind(this, courierService)}
                        />
                      </div>
                      {renderLabel()}
                    </label>
                    )
                  }
                  {
                    isSelected && (
                      <SelectTimeDelivery {...selectTimeDeliveryProps} />
                    )
                  }
                </React.Fragment>
              )
            })
          }
          <Button
            dark
            disabled={submitDisabled}
            className={style.button}
            text={t('screens.checkout.checkout.buttons.continue')}
            onClick={onConfirmDelivery}
          />
        </form>
      </div>
    </div>
  )
}

export default ShippingView
