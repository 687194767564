import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { Elements, ElementsConsumer, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import { Button } from '../../../../ui'
import Modal from '../../../../ui/modal';
import useStyles from './payment-stripe-modal-style'

export default function PaymentStripeModalView({
  errorMessage,
  expireCount,
  isOpen,
  loading,
  payParams,
  confirmCount,
  onConfirmClick,
  onRequestClose,
}) {
  const styles = useStyles()
  const { t, i18n } = useTranslation()
  const currentLocale = i18n.language
  const expired = _.isNumber(expireCount) && expireCount <= 0
  const expireTime = _.isNumber(expireCount) && {
    minute: `${_.floor(expireCount / 60)}`.padStart(2, '0'),
    second: `${expireCount % 60}`.padStart(2, '0'),
  }

  return (
    <Modal
      dialog
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldCloseOnEsc={false}
    >
      <div className={styles.dialogContainer}>
        <div className={styles.dialogBody}>
          <div className={styles.errorMessage}>{errorMessage}</div>
        </div>
        <Elements
          stripe={loadStripe(_.get(payParams, 'publishable_key'))}
          options={{ clientSecret: _.get(payParams, 'client_secret'), locale: currentLocale }}
        >
          <ElementsConsumer>
            {({ stripe, elements }) => (
              <>
                <PaymentElement />
                <div className={styles.dialogButtons}>
                  <Button
                    className={styles.button}
                    dark
                    disabled={!stripe || loading || confirmCount > 0 || expired}
                    text={
                      expired
                        ? t('screens.checkout.checkout.paymentStripeModal.buttons.expired')
                        : t('screens.checkout.checkout.paymentStripeModal.buttons.confirm', {
                          count: confirmCount,
                          context: confirmCount <= 0 ? '0' : '1', // FL: added context to i18n key since i18next's Plural function doesn't work.
                        })
                      }
                    onClick={() => { onConfirmClick({ stripe, elements }) }}
                  />
                  <Button
                    className={styles.button}
                    border
                    disabled={!stripe || loading}
                    text={t('screens.checkout.checkout.paymentStripeModal.buttons.cancel')}
                    onClick={onRequestClose}
                  />
                </div>
                {
                  expireTime && (
                  <div className={styles.expireCount}>
                    {
                      t('screens.checkout.checkout.paymentStripeModal.expireCountDown', {
                        ...expireTime,
                        context: expired ? '1' : '0',
                      })
                    }
                  </div>
                  )
                }
              </>
            )}
          </ElementsConsumer>
        </Elements>
      </div>
    </Modal>
  )
}
