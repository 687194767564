import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles((theme) => ({
  container: {
    paddingBottom: '3rem',
  },

  errorMessage: {
    color: theme.colors.alert,
    marginBottom: '2rem',
    marginTop: '2rem',
    textAlign: 'left',
  },
  [`@media (min-width: ${theme.mediaQueries.desktop}px)`]: {
    container: {
      paddingBottom: '6rem',
    },

  },
}))

export default useStyles
