import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import {
  useSystemSettings,
} from 'react-omnitech-api'
import CouponsView from './coupons-view'

const CouponsController = ({
  isolateCart = {},
  progress,
  onAddCoupon,
  onRemoveCoupon,
}) => {
  // prepare hook
  const { getSystemSetting } = useSystemSettings()

  // internal state
  const [couponCode, setCouponCode] = useState('')
  const [couponCodeError, setCouponCodeError] = useState('')

  // local variables
  const {
    cartCouponTokens = [],
  } = isolateCart
  const isCouponMarketPlaceEnabled = getSystemSetting('coupon.market_place.enable')
  const multipleCouponEnabled = getSystemSetting('coupon.multiple_coupon.enable')

  // useMemo
  const active = useMemo(() => (
    progress.indexOf('coupon') !== -1
  ), [progress])
  const applyDisabled = useMemo(() => {
    if (
      _.isEmpty(couponCode)
      || (!multipleCouponEnabled && cartCouponTokens.length > 1)
    ) {
      return true
    }

    return false
  }, [cartCouponTokens, couponCode, multipleCouponEnabled])
  const validationErrors = _.get(isolateCart, 'validationErrors', [])

  async function handleApplyCoupon() {
    try {
      setCouponCodeError('')
      await onAddCoupon(_.trim(couponCode))
      setCouponCode('')
    } catch (error) {
      console.log('handleApplyCoupon error: ', error)
      const batchActionError = _.find(error.batchActionErrors, 'message') || {}
      const generalError = _.get(error, 'generalError', {})
      const errorMessage = !_.isEmpty(batchActionError)
        ? batchActionError.message
        : generalError.message
      setCouponCodeError(errorMessage)
    }
  }

  function handleRemoveCoupon(token) {
    onRemoveCoupon(token)
  }

  function handleCouponCodeChange(value) {
    setCouponCode(value)
  }

  const viewProps = {
    active,
    applyDisabled,
    cartCouponTokens,
    couponCode,
    couponCodeError,
    isCouponMarketPlaceEnabled,
    multipleCouponEnabled,
    validationErrors,
    onAddCoupon: handleApplyCoupon,
    onCouponCodeChange: handleCouponCodeChange,
    onRemoveCoupon: handleRemoveCoupon,
  }

  return (
    <CouponsView {...viewProps} />
  )
}

export default CouponsController
