import _ from 'lodash'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useCouponMarketplace } from '../../../../hook'
import useStyles from './coupons-style'
import Title from '../title/title-view'
import { Input, Button, AppliedCoupon } from '../../../../ui'
import { combineClassNames } from '../../../../helpers'
import getCartValidationErrors from '../../../../helpers/get-cart-validation-errors'

const CouponsView = ({
  active,
  applyDisabled,
  cartCouponTokens,
  couponCode,
  couponCodeError,
  isCouponMarketPlaceEnabled,
  validationErrors,
  onAddCoupon,
  onCouponCodeChange,
  onRemoveCoupon,
}) => {
  const {
    onCouponMarketplaceOpen,
  } = useCouponMarketplace()

  const { t } = useTranslation()

  const handleCouponCodeChange = (e) => {
    const { value } = e.target
    onCouponCodeChange(value)
  }

  const styles = useStyles()

  return (
    <div className={combineClassNames([styles.promoCodeStyle, !active && styles.isClose])}>
      <Title
        isActive={active}
        text={t('screens.checkout.checkout.coupon.title')}
      />
      {
        active
          ? (
            <div>
              <h4>{t('screens.checkout.checkout.coupon.subtitle')}</h4>
              <div className={styles.boxCoupon}>
                <Input
                  value={couponCode}
                  onChange={handleCouponCodeChange}
                />
                <Button
                  dark
                  disabled={applyDisabled}
                  text={t('screens.checkout.checkout.coupon.apply')}
                  onClick={onAddCoupon}
                  className={styles.applyButton}
                />
              </div>
              { !_.isEmpty(couponCodeError) && (
                <p className={styles.errorMessage}>{couponCodeError}</p>
              )}
              {
                isCouponMarketPlaceEnabled && (
                  <Button
                    border
                    className={styles.redeemButtonStyle}
                    text={t('screens.checkout.checkout.coupon.redeem')}
                    onClick={onCouponMarketplaceOpen}
                  />
                )
              }
              {
                cartCouponTokens.map((cartCouponToken) => (
                  <AppliedCoupon
                    key={`cart-coupon-${cartCouponToken.id}`}
                    couponToken={cartCouponToken.couponToken}
                    onRemove={onRemoveCoupon}
                    cartValidationError={
                      _.first(
                        getCartValidationErrors(validationErrors, {
                          cartCouponTokenId: cartCouponToken.id,
                          couponTokenId: _.get(cartCouponToken, 'couponToken.id'),
                        }),
                      )
                    }
                  />
                ))
              }
            </div>
          )
          : ''
      }
    </div>
  )
}

export default CouponsView
